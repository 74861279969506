.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 730px;
  padding: 30px;

  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(73, 97, 168, 0.05);
  border-radius: 15px;

  @media (max-width: 750px) {
    padding: 24px 16px 45px 16px;
    width: 100%;
    min-width: 375px;
  }
}

.header {
  display: flex;
  margin-bottom: 31px;
  width: 100%;
  @media (max-width: 750px) {
    margin-bottom: 24px;
  }
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: #1c202b;

  @media (max-width: 750px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.new-notifications {
  display: inline-block;
  align-self: center;
  margin-left: 11px;
  padding: 1px 11px 4px 11px;
  height: 25px;

  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
  background: #0a327b;
  border-radius: 6px;
}

.mark-notifications {
  margin-left: auto;
  align-self: center;

  line-height: 20px;
  color: #5e6778;
  background: transparent;

  @media (hover: hover) {
    &:hover {
      color: #0a327b;
      cursor: pointer;
    }
  }
}

.notifications {
  list-style: none;
}
